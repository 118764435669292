'use client';

import { AnchorProvider, type Wallet } from '@coral-xyz/anchor';
import type { WalletError } from '@solana/wallet-adapter-base';
import { type AnchorWallet, ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { BitgetWalletAdapter, MathWalletAdapter, PhantomWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets';
import type { PublicKey } from '@solana/web3.js';
import { VeContext, buildClient } from '@ve33/sdk';
import { type YevefiClient, YevefiContext, buildYevefiClient } from '@yevefi/sdk';
import { type PropsWithChildren, useCallback, useMemo } from 'react';
import { useCluster } from './ClusterProvider';
export const DappProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const {
    cluster
  } = useCluster();
  const wallets = useMemo(() => [new BitgetWalletAdapter({
    network: cluster.network
  }), new PhantomWalletAdapter({
    network: cluster.network
  }), new SolflareWalletAdapter({
    network: cluster.network
  }), new MathWalletAdapter({
    network: cluster.network
  }), new TorusWalletAdapter({
    params: {
      network: cluster.network
    }
  })], [cluster.network]);
  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);
  return <ConnectionProvider endpoint={cluster.endpoint}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>;
};
export const useAnchor = (): AnchorProvider => {
  const {
    connection
  } = useConnection();
  const wallet = useWallet();
  if (!wallet || connection === undefined) {
    throw new Error('Wallet not connected');
  }
  return new AnchorProvider(connection, wallet as AnchorWallet, {
    commitment: 'confirmed'
  });
};
export const useClient = (programId: PublicKey): {
  ctx: YevefiContext;
  client: YevefiClient;
} => {
  const {
    connection,
    wallet
  } = useAnchor();
  const ctx = YevefiContext.from(connection, wallet, programId);
  return {
    ctx,
    client: buildYevefiClient(ctx)
  };
};
export const useVeClient = (programId: PublicKey) => {
  const {
    connection,
    wallet
  } = useAnchor();
  const ctx = VeContext.create(connection, wallet as Wallet, programId);
  const client = buildClient(ctx);
  return {
    ctx,
    client
  };
};