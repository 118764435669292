import { BN, type Program } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { IncreaseLockParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const inscrease_lock = async (
	program: Program<Ve33>,
	params: IncreaseLockParams,
) => {
	const { lockId, amount, authority, mintToken } = params;

	const [lockData] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(lockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const lock = await program.account.lockData.fetch(lockData);
	if (!lock) {
		throw new Error("Lock not found");
	}

	const lockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		lockData,
		true,
	);

	const lockTokenAccount = getAssociatedTokenAddressSync(mintToken, authority);
	const ix = await program.methods.increaseLock(amount).accounts({
		funder: authority,
		lockData,
		lockTokenAccount,
		lockTokenMint: mintToken,
		lockVaultAccount,
		tokenProgram: TOKEN_PROGRAM_ID,
		systemProgram: SystemProgram.programId,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
	});

	return ix;
};
