"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.swapV2Ix = swapV2Ix;
const public_1 = require("../../types/public");
const remaining_accounts_util_1 = require("../../utils/remaining-accounts-util");
/**
 * Perform a swap in this Yevefi
 *
 * #### Special Errors
 * - `ZeroTradableAmount` - User provided parameter `amount` is 0.
 * - `InvalidSqrtPriceLimitDirection` - User provided parameter `sqrt_price_limit` does not match the direction of the trade.
 * - `SqrtPriceOutOfBounds` - User provided parameter `sqrt_price_limit` is over Whirlppool's max/min bounds for sqrt-price.
 * - `InvalidTickArraySequence` - User provided tick-arrays are not in sequential order required to proceed in this trade direction.
 * - `TickArraySequenceInvalidIndex` - The swap loop attempted to access an invalid array index during the query of the next initialized tick.
 * - `TickArrayIndexOutofBounds` - The swap loop attempted to access an invalid array index during tick crossing.
 * - `LiquidityOverflow` - Liquidity value overflowed 128bits during tick crossing.
 * - `InvalidTickSpacing` - The swap pool was initialized with tick-spacing of 0.
 * - `AmountCalcOverflow` - The required token amount exceeds the u64 range.
 * - `AmountRemainingOverflow` - Result does not match the specified amount.
 * - `DifferentYevefiTickArrayAccount` - The provided tick array account does not belong to the yevefi.
 * - `PartialFillError` - Partially filled when sqrtPriceLimit = 0 and amountSpecifiedIsInput = false.
 *
 * ### Parameters
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - {@link SwapV2Params}
 * @returns - Instruction to perform the action.
 */
function swapV2Ix(program, params) {
    const { amount, otherAmountThreshold, sqrtPriceLimit, amountSpecifiedIsInput, aToB, yevefi, tokenAuthority, tokenMintA, tokenMintB, tokenOwnerAccountA, tokenVaultA, tokenOwnerAccountB, tokenVaultB, tokenTransferHookAccountsA, tokenTransferHookAccountsB, tokenProgramA, tokenProgramB, tickArray0, tickArray1, tickArray2, oracle, supplementalTickArrays, } = params;
    const [remainingAccountsInfo, remainingAccounts] = new remaining_accounts_util_1.RemainingAccountsBuilder()
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookA, tokenTransferHookAccountsA)
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookB, tokenTransferHookAccountsB)
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.SupplementalTickArrays, (0, remaining_accounts_util_1.toSupplementalTickArrayAccountMetas)(supplementalTickArrays))
        .build();
    const ix = program.instruction.swapV2(amount, otherAmountThreshold, sqrtPriceLimit, amountSpecifiedIsInput, aToB, remainingAccountsInfo, {
        accounts: {
            tokenProgramA,
            tokenProgramB,
            memoProgram: public_1.MEMO_PROGRAM_ADDRESS,
            tokenAuthority: tokenAuthority,
            yevefi,
            tokenMintA,
            tokenMintB,
            tokenOwnerAccountA,
            tokenVaultA,
            tokenOwnerAccountB,
            tokenVaultB,
            tickArray0,
            tickArray1,
            tickArray2,
            oracle,
        },
        remainingAccounts,
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
