import type { Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { Ve33 } from "../types/ve_33";

export const get_vote_config = async (program: Program<Ve33>) => {
	const [voteConfig] = PublicKey.findProgramAddressSync(
		[Buffer.from("vote_config")],
		program.programId,
	);
	const data = await program.account.voteConfig.fetch(voteConfig);
	return data;
};
