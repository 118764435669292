import { BN, type Program } from "@coral-xyz/anchor";
import {
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import type { MergeLockParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const merge_lock = async (
	program: Program<Ve33>,
	params: MergeLockParams,
) => {
	const { selectLockId, targetLockId, mintToken, mintNftToken, authority } =
		params;

	if (
		!mintToken ||
		!mintNftToken ||
		!selectLockId ||
		!targetLockId ||
		!authority
	) {
		throw new Error("Invalid parameters");
	}

	const [souceDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(selectLockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const [targetDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(targetLockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const sourceVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		souceDataPDA,
		true,
	);

	const targetVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		targetDataPDA,
		true,
	);

	const sourceData = await program.account.lockData.fetch(souceDataPDA);
	if (!sourceData) {
		throw new Error("Select Lock not found");
	}

	const targetData = await program.account.lockData.fetch(targetDataPDA);
	if (!targetData) {
		throw new Error("Target Lock not found");
	}
	const tokenAccount = getAssociatedTokenAddressSync(mintNftToken, authority);

	const ix = await program.methods.mergeLock().accounts({
		funder: authority,
		sourceData: souceDataPDA,
		targetData: targetDataPDA,
		lockTokenMint: mintToken,
		sourceVaultAccount: sourceVaultAccount,
		targetVaultAccount: targetVaultAccount,

		mint: mintNftToken,
		tokenAccount: tokenAccount,
		tokenProgram: TOKEN_PROGRAM_ID,
	});
	return ix;
};
