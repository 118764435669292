import { SOL, YEVE } from './tokens'

export const DEFAULT_PROTOCOL_FEE_RATE = 300
export const DEFAULT_TOKEN0 = YEVE
export const DEFAULT_TOKEN1 = SOL

export const DEFAULT_DEV_FEE_RATE = 0.02
export const PIN_TOKENS = [SOL, YEVE]

export const LOCALNET_RPC = 'http://127.0.0.1:8899'
export const LOCK_RATE = 1
