import { BN, type Program } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import {
	Keypair,
	PublicKey,
	SYSVAR_RENT_PUBKEY,
	SystemProgram,
} from "@solana/web3.js";
import type { CreateLockParams } from ".";
import { TOKEN_METADATA_PROGRAM_ID } from "../constants";
import type { Ve33 } from "../types/ve_33";

export const create_lock = async (
	program: Program<Ve33>,
	params: CreateLockParams,
) => {
	const { amount, authority, mintTokenProgramId } = params;
	const [mintToken] = PublicKey.findProgramAddressSync(
		[Buffer.from("mint")],
		mintTokenProgramId,
	);

	if (!amount || !authority || !mintTokenProgramId) {
		throw new Error("Invalid parameters");
	}

	const [lockConfig] = PublicKey.findProgramAddressSync(
		[Buffer.from("lock")],
		program.programId,
	);

	const config = await program.account.lock.fetch(lockConfig);
	if (!config) {
		throw new Error("Lock not initialized");
	}

	const [lockData] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(config.nextLockIdentifier).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const lockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		lockData,
		true,
	);

	const lockTokenAccount = getAssociatedTokenAddressSync(mintToken, authority);
	const mintAccount = Keypair.generate();
	const [metadataAccount] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("metadata"),
			TOKEN_METADATA_PROGRAM_ID.toBuffer(),
			mintAccount.publicKey.toBuffer(),
		],
		TOKEN_METADATA_PROGRAM_ID,
	);

	const [editionAccount] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("metadata"),
			TOKEN_METADATA_PROGRAM_ID.toBuffer(),
			mintAccount.publicKey.toBuffer(),
			Buffer.from("edition"),
		],
		TOKEN_METADATA_PROGRAM_ID,
	);

	const associatedTokenAccount = getAssociatedTokenAddressSync(
		mintAccount.publicKey,
		authority,
	);

	const lockIx = await program.methods
		.lock(amount)
		.accounts({
			funder: authority,
			lock: lockConfig,
			lockData: lockData,
			lockTokenMint: mintToken,
			lockVaultAccount: lockVaultAccount,
			lockTokenAccount: lockTokenAccount,
			mint: mintAccount.publicKey,
			associatedTokenAccount: associatedTokenAccount,
			systemProgram: SystemProgram.programId,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_PROGRAM_ID,
		})
		.instruction();

	const ix = await program.methods
		.lockMetadata()
		.accounts({
			funder: authority,
			mint: mintAccount.publicKey,
			metadataAccount: metadataAccount,
			editionAccount: editionAccount,
			associatedTokenAccount: associatedTokenAccount,
			lockData: lockData,
			tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
			systemProgram: SystemProgram.programId,
			tokenProgram: TOKEN_PROGRAM_ID,
			rent: SYSVAR_RENT_PUBKEY,
		})
		.signers([mintAccount])
		.preInstructions([lockIx]);

	return ix;
};
