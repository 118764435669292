"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteTokenBadgeIx = deleteTokenBadgeIx;
/**
 * Deletes a TokenBadge account.
 *
 * @category Instructions
 * @param program - program object containing services required to generate the instruction
 * @param params - DeleteTokenBadgeParams object
 * @returns - Instruction to perform the action.
 */
function deleteTokenBadgeIx(program, params) {
    const { yevefisConfig, yevefisConfigExtension, tokenBadgeAuthority, tokenMint, tokenBadge, receiver, } = params;
    const ix = program.instruction.deleteTokenBadge({
        accounts: {
            yevefisConfig,
            yevefisConfigExtension,
            tokenBadgeAuthority,
            tokenMint,
            tokenBadge,
            receiver,
        },
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
