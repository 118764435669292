import type { Program } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { XConvertParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const x_convert = async (
	program: Program<Ve33>,
	params: XConvertParams,
) => {
	const {
		amount,
		authority,
		feeReceiver,
		yeveTokenProgramId,
		xyeveTokenProgramId,
	} = params;

	const [yeveMint, yeveBump] = PublicKey.findProgramAddressSync(
		[Buffer.from("mint")],
		yeveTokenProgramId,
	);

	const [yeveTokenData] = PublicKey.findProgramAddressSync(
		[Buffer.from("token"), yeveMint.toBuffer()],
		yeveTokenProgramId,
	);

	const [xyeveMint] = PublicKey.findProgramAddressSync(
		[Buffer.from("mint")],
		xyeveTokenProgramId,
	);

	const [convert] = PublicKey.findProgramAddressSync(
		[Buffer.from("convert")],
		program.programId,
	);

	const tokenAccount = getAssociatedTokenAddressSync(xyeveMint, authority);
	const yeveRecipientAccount = getAssociatedTokenAddressSync(
		yeveMint,
		authority,
	);

	const ix = await program.methods.xConvert(amount, yeveBump).accounts({
		funder: authority,
		convert: convert,
		feeReceiver: feeReceiver,
		tokenAccount: tokenAccount,
		tokenMint: xyeveMint,

		mintProgramPda: program.programId,
		mintTokenProgram: yeveTokenProgramId,
		yeveTokenData: yeveTokenData,
		yeveRecipientAccount: yeveRecipientAccount,
		yeveTokenMint: yeveMint,

		tokenProgram: TOKEN_PROGRAM_ID,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
		systemProgram: SystemProgram.programId,
	});

	return ix;
};
