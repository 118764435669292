"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTokenBadgeAuthorityIx = setTokenBadgeAuthorityIx;
/**
 * Sets the token badge authority for a YevefisConfigExtension.
 * The token badge authority can initialize TokenBadge.
 * Only the config extension authority has permission to invoke this instruction.
 *
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - SetTokenBadgeAuthorityParams object
 * @returns - Instruction to perform the action.
 */
function setTokenBadgeAuthorityIx(program, params) {
    const { yevefisConfig, yevefisConfigExtension, configExtensionAuthority, newTokenBadgeAuthority, } = params;
    const ix = program.instruction.setTokenBadgeAuthority({
        accounts: {
            yevefisConfig,
            yevefisConfigExtension,
            configExtensionAuthority,
            newTokenBadgeAuthority,
        },
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
