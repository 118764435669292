"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeConfigExtensionIx = initializeConfigExtensionIx;
const web3_js_1 = require("@solana/web3.js");
/**
 * Initializes a YevefisConfigExtension account that hosts info & authorities
 *
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - InitConfigExtensionParams object
 * @returns - Instruction to perform the action.
 */
function initializeConfigExtensionIx(program, params) {
    const { yevefisConfig, yevefisConfigExtensionPda, funder, feeAuthority } = params;
    const ix = program.instruction.initializeConfigExtension({
        accounts: {
            config: yevefisConfig,
            configExtension: yevefisConfigExtensionPda.publicKey,
            funder,
            feeAuthority,
            systemProgram: web3_js_1.SystemProgram.programId,
        },
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
