import { PublicKey } from "@solana/web3.js";
import contractIdl from "../idl/ve_33.json";
import xyeveIdl from "../idl/xyeve.json";
import yeveIdl from "../idl/yeve.json";

export const VE_PROGRAM_INTERFACE = JSON.parse(JSON.stringify(contractIdl));
export const YEVE_PROGRAM_INTERFACE = JSON.parse(JSON.stringify(yeveIdl));
export const XYEVE_PROGRAM_INTERFACE = JSON.parse(JSON.stringify(xyeveIdl));

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
	"metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s",
);
