import { BN, type Program } from "@coral-xyz/anchor";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import {
	Keypair,
	PublicKey,
	SYSVAR_RENT_PUBKEY,
	SystemProgram,
} from "@solana/web3.js";
import type { SplitLockParams } from ".";
import { TOKEN_METADATA_PROGRAM_ID } from "../constants";
import type { Ve33 } from "../types/ve_33";

export const split_lock = async (
	program: Program<Ve33>,
	params: SplitLockParams,
) => {
	const { selectLockId, amount, mintToken, mintNftToken, authority } = params;
	if (!mintToken || !selectLockId || !amount || !authority || !mintNftToken) {
		throw new Error("Invalid parameters");
	}
	const [lockConfig] = PublicKey.findProgramAddressSync(
		[Buffer.from("lock")],
		program.programId,
	);
	const config = await program.account.lock.fetch(lockConfig);
	if (!config) {
		throw new Error("Lock not initialized");
	}

	const [sourceLockPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(selectLockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);
	const sourceData = await program.account.lockData.fetch(sourceLockPDA);
	if (!sourceData) {
		throw new Error("Select Lock not found");
	}
	const sourceLockTokenAccount = getAssociatedTokenAddressSync(
		mintNftToken,
		authority,
	);
	const sourceLockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		sourceLockPDA,
		true,
	);
	const tokenAccount = getAssociatedTokenAddressSync(mintToken, authority);
	const ix = amount.map(async (item, index) => {
		const nextLockIdentifier = config.nextLockIdentifier.addn(index);
		console.log("nextLockIdentifier", nextLockIdentifier);
		const [lockDataPDA] = PublicKey.findProgramAddressSync(
			[
				Buffer.from("lock_data"),
				authority.toBuffer(),
				nextLockIdentifier.toArrayLike(Buffer, "le", 8),
			],
			program.programId,
		);
		const mintAccount = Keypair.generate();
		const lockVaultAccount = getAssociatedTokenAddressSync(
			mintToken,
			lockDataPDA,
			true,
		);
		const [metadataAccount] = PublicKey.findProgramAddressSync(
			[
				Buffer.from("metadata"),
				TOKEN_METADATA_PROGRAM_ID.toBuffer(),
				mintAccount.publicKey.toBuffer(),
			],
			TOKEN_METADATA_PROGRAM_ID,
		);
		const [editionAccount] = PublicKey.findProgramAddressSync(
			[
				Buffer.from("metadata"),
				TOKEN_METADATA_PROGRAM_ID.toBuffer(),
				mintAccount.publicKey.toBuffer(),
				Buffer.from("edition"),
			],
			TOKEN_METADATA_PROGRAM_ID,
		);
		const associatedTokenAccount = getAssociatedTokenAddressSync(
			mintAccount.publicKey,
			authority,
		);
		const lockTransaction = await program.methods
			.splitLock(item)
			.accounts({
				funder: authority,
				lock: lockConfig,
				lockData: lockDataPDA,
				mint: mintAccount.publicKey,
				lockTokenMint: mintToken,
				lockVaultAccount: lockVaultAccount,
				lockTokenAccount: tokenAccount,
				sourceLockData: sourceLockPDA,
				sourceLockVaultAccount: sourceLockVaultAccount,
				sourceTokenMint: mintNftToken,
				sourceTokenAccount: sourceLockTokenAccount,
				associatedTokenAccount: associatedTokenAccount,
				systemProgram: SystemProgram.programId,
				associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
				tokenProgram: TOKEN_PROGRAM_ID,
			})
			.signers([mintAccount])
			.instruction();
		const lockMetaDataTransaction = await program.methods
			.lockMetadata()
			.accounts({
				funder: authority,
				mint: mintAccount.publicKey,
				metadataAccount: metadataAccount,
				editionAccount: editionAccount,
				lockData: lockDataPDA,
				associatedTokenAccount: associatedTokenAccount,
				tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
				systemProgram: SystemProgram.programId,
				tokenProgram: TOKEN_PROGRAM_ID,
				rent: SYSVAR_RENT_PUBKEY,
			})
			.signers([mintAccount])
			.preInstructions([lockTransaction])
			.transaction();
		return {
			tx: lockMetaDataTransaction,
			signers: mintAccount,
		};
	});
	return ix;
};
