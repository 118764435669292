import { BN, type Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { GetVoteEscrowParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const get_vote_escrow = async (
	program: Program<Ve33>,
	params: GetVoteEscrowParams,
) => {
	const { identifier, owner, epoch } = params;
	const [voteData] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote_escrow"),
			owner.toBuffer(),
			new BN(identifier).toArrayLike(Buffer, "le", 8),
			new BN(epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);
	const data = await program.account.voteEscrow.fetch(voteData);
	return data;
};
