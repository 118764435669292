"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeTokenBadgeIx = initializeTokenBadgeIx;
const web3_js_1 = require("@solana/web3.js");
/**
 * Initializes a TokenBadge account.
 *
 * @category Instructions
 * @param program - program object containing services required to generate the instruction
 * @param params - InitializeTokenBadgeParams object
 * @returns - Instruction to perform the action.
 */
function initializeTokenBadgeIx(program, params) {
    const { yevefisConfig, yevefisConfigExtension, tokenBadgeAuthority, tokenMint, tokenBadgePda, funder, } = params;
    const ix = program.instruction.initializeTokenBadge({
        accounts: {
            yevefisConfig,
            yevefisConfigExtension,
            tokenBadgeAuthority,
            tokenMint,
            tokenBadge: tokenBadgePda.publicKey,
            funder,
            systemProgram: web3_js_1.SystemProgram.programId,
        },
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
