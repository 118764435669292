"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectRewardV2Ix = collectRewardV2Ix;
const __1 = require("../..");
const remaining_accounts_util_1 = require("../../utils/remaining-accounts-util");
/**
 * Collect rewards accrued for this reward index in a position.
 * Call updateFeesAndRewards before this to update the position to the newest accrued values.
 *
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - CollectRewardV2Params object
 * @returns - Instruction to perform the action.
 */
function collectRewardV2Ix(program, params) {
    const { yevefi, positionAuthority, position, positionTokenAccount, rewardMint, rewardOwnerAccount, rewardVault, rewardTransferHookAccounts, rewardIndex, rewardTokenProgram, } = params;
    const [remainingAccountsInfo, remainingAccounts] = new remaining_accounts_util_1.RemainingAccountsBuilder()
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookReward, rewardTransferHookAccounts)
        .build();
    const ix = program.instruction.collectRewardV2(rewardIndex, remainingAccountsInfo, {
        accounts: {
            yevefi,
            positionAuthority,
            position,
            positionTokenAccount,
            rewardMint,
            rewardOwnerAccount,
            rewardVault,
            rewardTokenProgram,
            memoProgram: __1.MEMO_PROGRAM_ADDRESS,
        },
        remainingAccounts,
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
