import { BN, type Program } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { ExistLockParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const exist_lock = async (
	program: Program<Ve33>,
	params: ExistLockParams,
) => {
	const { lockId, feeReceiver, mintToken, authority, mintNftToken } = params;

	if (!mintNftToken || !mintNftToken || !lockId || !authority || !feeReceiver) {
		throw new Error("Invalid parameters");
	}

	const [lockPDA] = PublicKey.findProgramAddressSync(
		[Buffer.from("lock")],
		program.programId,
	);

	const [lockDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(lockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const lock = await program.account.lockData.fetch(lockDataPDA);
	if (!lock) {
		throw new Error("Lock not found");
	}

	const lockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		lockDataPDA,
		true,
	);

	const reciverTokenAccount = getAssociatedTokenAddressSync(
		mintToken,
		authority,
	);
	const tokenAccount = getAssociatedTokenAddressSync(mintNftToken, authority);

	const ix = await program.methods.existLock().accounts({
		funder: authority,
		lock: lockPDA,
		lockData: lockDataPDA,
		lockVaultAccount: lockVaultAccount,
		receiverTokenAccount: reciverTokenAccount,

		mint: mintNftToken,
		tokenAccount: tokenAccount,
		lockTokenMint: mintToken,

		feeReceiver: feeReceiver,
		tokenProgram: TOKEN_PROGRAM_ID,
		systemProgram: SystemProgram.programId,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
	});

	return ix;
};
