"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectAllForPositionAddressesTxns = collectAllForPositionAddressesTxns;
exports.collectAllForPositionsTxns = collectAllForPositionsTxns;
const common_sdk_1 = require("@orca-so/common-sdk");
const spl_token_1 = require("@solana/spl-token");
const web3_js_1 = require("@solana/web3.js");
const ix_1 = require("../../ix");
const fetcher_1 = require("../../network/public/fetcher");
const public_1 = require("../../utils/public");
const token_extension_util_1 = require("../../utils/public/token-extension-util");
const txn_utils_1 = require("../../utils/txn-utils");
const yevefi_ata_utils_1 = require("../../utils/yevefi-ata-utils");
const update_fees_and_rewards_ix_1 = require("../update-fees-and-rewards-ix");
/**
 * Build a set of transactions to collect fees and rewards for a set of Yevefi Positions.
 *
 * @category Instructions
 * @experimental
 * @param ctx - YevefiContext object for the current environment.
 * @param params - CollectAllPositionAddressParams object
 * @param opts an {@link YevefiAccountFetchOptions} object to define fetch and cache options when accessing on-chain accounts
 * @returns A set of transaction-builders to resolve ATA for affliated tokens, collect fee & rewards for all positions.
 */
async function collectAllForPositionAddressesTxns(ctx, params, opts = fetcher_1.PREFER_CACHE) {
    const { positions, ...rest } = params;
    const fetchedPositions = await ctx.fetcher.getPositions(positions, opts);
    const positionMap = {};
    fetchedPositions.forEach((pos, addr) => {
        if (pos) {
            positionMap[addr] = pos;
        }
    });
    return collectAllForPositionsTxns(ctx, { positions: positionMap, ...rest });
}
/**
 * Build a set of transactions to collect fees and rewards for a set of Yevefi Positions.
 *
 * @experimental
 * @param ctx - YevefiContext object for the current environment.
 * @param params - CollectAllPositionParams object
 * @returns A set of transaction-builders to resolve ATA for affliated tokens, collect fee & rewards for all positions.
 */
async function collectAllForPositionsTxns(ctx, params) {
    const { positions, receiver, positionAuthority, positionOwner, payer } = params;
    const receiverKey = receiver ?? ctx.wallet.publicKey;
    const positionAuthorityKey = positionAuthority ?? ctx.wallet.publicKey;
    const positionOwnerKey = positionOwner ?? ctx.wallet.publicKey;
    const payerKey = payer ?? ctx.wallet.publicKey;
    const positionList = Object.entries(positions);
    if (positionList.length === 0) {
        return [];
    }
    const yevefiAddrs = positionList.map(([, pos]) => pos.yevefi.toBase58());
    const yevefis = await ctx.fetcher.getPools(yevefiAddrs, fetcher_1.PREFER_CACHE);
    const allMints = (0, yevefi_ata_utils_1.getTokenMintsFromYevefis)(Array.from(yevefis.values()));
    const accountExemption = await ctx.fetcher.getAccountRentExempt();
    const positionMintAddrs = positionList.map(([, pos]) => pos.positionMint);
    const positionMintInfos = await ctx.fetcher.getMintInfos(positionMintAddrs);
    // make cache
    await ctx.fetcher.getMintInfos(allMints.mintMap);
    // resolvedAtas[mint] => Instruction & { address }
    // if already ATA exists, Instruction will be EMPTY_INSTRUCTION
    const resolvedAtas = (0, txn_utils_1.convertListToMap)(await (0, common_sdk_1.resolveOrCreateATAs)(ctx.connection, receiverKey, allMints.mintMap.map((tokenMint) => ({ tokenMint })), async () => accountExemption, payerKey, true, // CreateIdempotent
    ctx.accountResolverOpts.allowPDAOwnerAddress, ctx.accountResolverOpts.createWrappedSolAccountMethod), allMints.mintMap.map((mint) => mint.toBase58()));
    const latestBlockhash = await ctx.connection.getLatestBlockhash();
    const txBuilders = [];
    // build tasks
    // For TokenProgram-TokenProgram pair pool, collectFees and 3 collectReward instructions can be packed into one transaction.
    // But if pool has TokenExtension, especially TransferHook, we can no longer pack all instructions into one transaction.
    // So transactions need to be broken up at a finer granularity.
    const collectionTasks = [];
    positionList.forEach(([positionAddr, position]) => {
        const yevefi = yevefis.get(position.yevefi.toBase58());
        if (!yevefi) {
            throw new Error(`Unable to process positionMint ${position.positionMint.toBase58()} - unable to derive yevefi ${position.yevefi.toBase58()}`);
        }
        const positionMintInfo = positionMintInfos.get(position.positionMint.toBase58());
        if (!positionMintInfo) {
            throw new Error(`Unable to process positionMint ${position.positionMint.toBase58()} - missing mint info`);
        }
        // add fee collection task
        collectionTasks.push({
            collectionType: "fee",
            positionAddr,
            position,
            yevefi,
            positionMintTokenProgramId: positionMintInfo.tokenProgram,
        });
        // add reward collection task
        yevefi.rewardInfos.forEach((rewardInfo, index) => {
            if (public_1.PoolUtil.isRewardInitialized(rewardInfo)) {
                collectionTasks.push({
                    collectionType: "reward",
                    rewardIndex: index,
                    positionAddr,
                    position,
                    yevefi,
                    positionMintTokenProgramId: positionMintInfo.tokenProgram,
                });
            }
        });
    });
    let cursor = 0;
    let pendingTxBuilder = null;
    let touchedMints = null;
    let lastUpdatedPosition = null;
    let reattempt = false;
    while (cursor < collectionTasks.length) {
        if (!pendingTxBuilder || !touchedMints) {
            pendingTxBuilder = new common_sdk_1.TransactionBuilder(ctx.connection, ctx.wallet, ctx.txBuilderOpts);
            touchedMints = new Set();
            resolvedAtas[spl_token_1.NATIVE_MINT.toBase58()] =
                common_sdk_1.TokenUtil.createWrappedNativeAccountInstruction(receiverKey, common_sdk_1.ZERO, accountExemption, undefined, // use default
                undefined, // use default
                ctx.accountResolverOpts.createWrappedSolAccountMethod);
        }
        // Build collect instructions
        const task = collectionTasks[cursor];
        const alreadyUpdated = lastUpdatedPosition === task.positionAddr;
        const collectIxForPosition = await constructCollectIxForPosition(ctx, task, alreadyUpdated, positionOwnerKey, positionAuthorityKey, resolvedAtas, touchedMints);
        const positionTxBuilder = new common_sdk_1.TransactionBuilder(ctx.connection, ctx.wallet, ctx.txBuilderOpts);
        positionTxBuilder.addInstructions(collectIxForPosition);
        // Attempt to push the new instructions into the pending builder
        // Iterate to the next task if possible
        // Create a builder and reattempt if the current one is full.
        const mergeable = await (0, txn_utils_1.checkMergedTransactionSizeIsValid)(ctx, [pendingTxBuilder, positionTxBuilder], latestBlockhash);
        if (mergeable) {
            pendingTxBuilder.addInstruction(positionTxBuilder.compressIx(false));
            cursor += 1;
            lastUpdatedPosition = task.positionAddr;
            reattempt = false;
        }
        else {
            if (reattempt) {
                throw new Error(`Unable to fit collection ix for ${task.position.positionMint.toBase58()} in a Transaction.`);
            }
            txBuilders.push(pendingTxBuilder);
            pendingTxBuilder = null;
            touchedMints = null;
            lastUpdatedPosition = null;
            reattempt = true;
        }
    }
    if (pendingTxBuilder) {
        txBuilders.push(pendingTxBuilder);
    }
    return txBuilders;
}
// TODO: Once individual collect ix for positions is implemented, maybe migrate over if it can take custom ATA?
const constructCollectIxForPosition = async (ctx, task, alreadyUpdated, positionOwner, positionAuthority, resolvedAtas, touchedMints) => {
    const ixForPosition = [];
    const { yevefi: yevefiKey, liquidity, tickLowerIndex, tickUpperIndex, positionMint, } = task.position;
    const positionMintTokenProgramId = task.positionMintTokenProgramId;
    const yevefi = task.yevefi;
    const { tickSpacing } = yevefi;
    const mintA = yevefi.tokenMintA.toBase58();
    const mintB = yevefi.tokenMintB.toBase58();
    const tokenExtensionCtx = await token_extension_util_1.TokenExtensionUtil.buildTokenExtensionContext(ctx.fetcher, yevefi, fetcher_1.PREFER_CACHE);
    const positionTokenAccount = (0, spl_token_1.getAssociatedTokenAddressSync)(positionMint, positionOwner, ctx.accountResolverOpts.allowPDAOwnerAddress, positionMintTokenProgramId);
    // Update fee and reward values if necessary
    if (!liquidity.eq(common_sdk_1.ZERO) && !alreadyUpdated) {
        ixForPosition.push((0, update_fees_and_rewards_ix_1.updateFeesAndRewardsIx)(ctx.program, {
            position: new web3_js_1.PublicKey(task.positionAddr),
            yevefi: yevefiKey,
            tickArrayLower: public_1.PDAUtil.getTickArray(ctx.program.programId, yevefiKey, public_1.TickUtil.getStartTickIndex(tickLowerIndex, tickSpacing)).publicKey,
            tickArrayUpper: public_1.PDAUtil.getTickArray(ctx.program.programId, yevefiKey, public_1.TickUtil.getStartTickIndex(tickUpperIndex, tickSpacing)).publicKey,
        }));
    }
    if (task.collectionType === "fee") {
        // Collect Fee
        if (!touchedMints.has(mintA)) {
            ixForPosition.push(resolvedAtas[mintA]);
            touchedMints.add(mintA);
        }
        if (!touchedMints.has(mintB)) {
            ixForPosition.push(resolvedAtas[mintB]);
            touchedMints.add(mintB);
        }
        const collectFeesBaseParams = {
            yevefi: yevefiKey,
            position: new web3_js_1.PublicKey(task.positionAddr),
            positionAuthority,
            positionTokenAccount,
            tokenOwnerAccountA: resolvedAtas[mintA].address,
            tokenOwnerAccountB: resolvedAtas[mintB].address,
            tokenVaultA: yevefi.tokenVaultA,
            tokenVaultB: yevefi.tokenVaultB,
        };
        ixForPosition.push(!token_extension_util_1.TokenExtensionUtil.isV2IxRequiredPool(tokenExtensionCtx)
            ? ix_1.YevefiIx.collectFeesIx(ctx.program, collectFeesBaseParams)
            : ix_1.YevefiIx.collectFeesV2Ix(ctx.program, {
                ...collectFeesBaseParams,
                tokenMintA: tokenExtensionCtx.tokenMintWithProgramA.address,
                tokenMintB: tokenExtensionCtx.tokenMintWithProgramB.address,
                tokenProgramA: tokenExtensionCtx.tokenMintWithProgramA.tokenProgram,
                tokenProgramB: tokenExtensionCtx.tokenMintWithProgramB.tokenProgram,
                ...(await token_extension_util_1.TokenExtensionUtil.getExtraAccountMetasForTransferHookForPool(ctx.connection, tokenExtensionCtx, collectFeesBaseParams.tokenVaultA, collectFeesBaseParams.tokenOwnerAccountA, collectFeesBaseParams.yevefi, // vault to owner, so pool is authority
                collectFeesBaseParams.tokenVaultB, collectFeesBaseParams.tokenOwnerAccountB, collectFeesBaseParams.yevefi // vault to owner, so pool is authority
                )),
            }));
    }
    else {
        // Collect Rewards
        // TODO: handle empty vault values?
        const index = task.rewardIndex;
        const rewardInfo = yevefi.rewardInfos[index];
        const mintReward = rewardInfo.mint.toBase58();
        if (!touchedMints.has(mintReward)) {
            ixForPosition.push(resolvedAtas[mintReward]);
            touchedMints.add(mintReward);
        }
        const collectRewardBaseParams = {
            yevefi: yevefiKey,
            position: new web3_js_1.PublicKey(task.positionAddr),
            positionAuthority,
            positionTokenAccount,
            rewardIndex: index,
            rewardOwnerAccount: resolvedAtas[mintReward].address,
            rewardVault: rewardInfo.vault,
        };
        ixForPosition.push(!token_extension_util_1.TokenExtensionUtil.isV2IxRequiredReward(tokenExtensionCtx, index)
            ? ix_1.YevefiIx.collectRewardIx(ctx.program, collectRewardBaseParams)
            : ix_1.YevefiIx.collectRewardV2Ix(ctx.program, {
                ...collectRewardBaseParams,
                rewardMint: tokenExtensionCtx.rewardTokenMintsWithProgram[index].address,
                rewardTokenProgram: tokenExtensionCtx.rewardTokenMintsWithProgram[index]
                    .tokenProgram,
                rewardTransferHookAccounts: await token_extension_util_1.TokenExtensionUtil.getExtraAccountMetasForTransferHook(ctx.connection, tokenExtensionCtx.rewardTokenMintsWithProgram[index], collectRewardBaseParams.rewardVault, collectRewardBaseParams.rewardOwnerAccount, collectRewardBaseParams.yevefi // vault to owner, so pool is authority
                ),
            }));
    }
    return ixForPosition;
};
