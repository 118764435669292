import { BN, type Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { GetVoteParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const get_vote = async (
	program: Program<Ve33>,
	params: GetVoteParams,
) => {
	const { epoch } = params;
	const [voteData] = PublicKey.findProgramAddressSync(
		[Buffer.from("vote"), new BN(epoch).toArrayLike(Buffer, "le", 8)],
		program.programId,
	);
	const data = await program.account.vote.fetch(voteData);
	return data;
};
