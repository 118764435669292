import type { Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { Ve33 } from "../types/ve_33";

export const convert = async (program: Program<Ve33>) => {
	const [convert] = PublicKey.findProgramAddressSync(
		[Buffer.from("convert")],
		program.programId,
	);
	const data = await program.account.convert.fetch(convert);
	return data;
};
