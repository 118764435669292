"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.closePositionWithTokenExtensionsIx = closePositionWithTokenExtensionsIx;
const spl_token_1 = require("@solana/spl-token");
/**
 * Close a position in a Yevefi. Burns the position token in the owner's wallet.
 * Mint and TokenAccount are based on Token-2022. And Mint accout will be also closed.
 *
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - ClosePositionWithTokenExtensionsParams object
 * @returns - Instruction to perform the action.
 */
function closePositionWithTokenExtensionsIx(program, params) {
    const ix = program.instruction.closePositionWithTokenExtensions({
        accounts: {
            ...params,
            token2022Program: spl_token_1.TOKEN_2022_PROGRAM_ID,
        },
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
