"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openPositionWithTokenExtensionsIx = openPositionWithTokenExtensionsIx;
const __1 = require("..");
const instructions_util_1 = require("../utils/instructions-util");
/**
 * Open a position in a Yevefi. A unique token will be minted to represent the position
 * in the users wallet. Additional TokenMetadata extension is initialized to identify the token if requested.
 * Mint and Token account are based on Token-2022.
 * The position will start off with 0 liquidity.
 *
 * #### Special Errors
 * `InvalidTickIndex` - If a provided tick is out of bounds, out of order or not a multiple of the tick-spacing in this pool.
 *
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - OpenPositionWithTokenExtensionsParams object and a derived PDA that hosts the position's metadata.
 * @returns - Instruction to perform the action.
 */
function openPositionWithTokenExtensionsIx(program, params) {
    const { tickLowerIndex, tickUpperIndex, withTokenMetadataExtension } = params;
    const ix = program.instruction.openPositionWithTokenExtensions(tickLowerIndex, tickUpperIndex, withTokenMetadataExtension, {
        accounts: {
            ...(0, instructions_util_1.openPositionWithTokenExtensionsAccounts)(params),
            metadataUpdateAuth: __1.YEVEFI_NFT_UPDATE_AUTH,
        },
    });
    // TODO: Require Keypair and auto sign this ix
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
