import type { PublicKey } from "@solana/web3.js";
import type { CreateBribesParams } from "./bribes";
import { create_bribes } from "./bribes/create_bribes";
import type { VeContext } from "./context";
import type { VeConvertParams, VestingParams, XConvertParams } from "./convert";
import { convert } from "./convert/convert";
import { ve_convert } from "./convert/ve_convert";
import { vesting } from "./convert/vesting";
import { x_convert } from "./convert/x_convert";
import type {
	CreateLockParams,
	ExistLockParams,
	GetLockParams,
	IncreaseLockParams,
	MergeLockParams,
	SplitLockParams,
	TransferLockParams,
} from "./lock";
import { create_lock } from "./lock/create_lock";
import { exist_lock } from "./lock/exist_lock";
import { get_lock } from "./lock/get_lock";
import { get_locks } from "./lock/get_locks";
import { inscrease_lock } from "./lock/inscrease_lock";
import { lock } from "./lock/lock";
import { merge_lock } from "./lock/merge_lock";
import { split_lock } from "./lock/split_lock";
import { transfer_lock } from "./lock/transfer_lock";
import type {
	CreateVoteParams,
	GetVoteEscrowParams,
	GetVoteParams,
	VoteRewardParams,
} from "./vote";
import { create_vote } from "./vote/create_vote";
import { get_vote } from "./vote/get_vote";
import { get_vote_config } from "./vote/get_vote_config";
import { get_vote_escrow } from "./vote/get_vote_escrow";
import { get_votes } from "./vote/get_votes";
import { vote_reward } from "./vote/rewards";

export interface IVeClient {
	getContext: () => VeContext;
}

export class VeClient implements IVeClient {
	private readonly ctx: VeContext;

	constructor(ctx: VeContext) {
		this.ctx = ctx;
	}

	getContext(): VeContext {
		return this.ctx;
	}

	async getLockConfig() {
		return lock(this.ctx.program);
	}

	async getConvertConfig() {
		return convert(this.ctx.program);
	}

	async createLock(params: CreateLockParams) {
		return create_lock(this.ctx.program, params);
	}

	async increaseLock(params: IncreaseLockParams) {
		return inscrease_lock(this.ctx.program, params);
	}

	async mergeLock(params: MergeLockParams) {
		return merge_lock(this.ctx.program, params);
	}

	async splitLock(params: SplitLockParams) {
		return split_lock(this.ctx.program, params);
	}

	async existLock(params: ExistLockParams) {
		return exist_lock(this.ctx.program, params);
	}

	async transferLock(params: TransferLockParams) {
		return transfer_lock(this.ctx.program, params);
	}

	async getLocks(owner: PublicKey) {
		return get_locks(this.ctx.program, owner);
	}

	async getLock(params: GetLockParams) {
		return get_lock(this.ctx.program, params);
	}

	async XConvert(params: XConvertParams) {
		return x_convert(this.ctx.program, params);
	}

	async veConvert(params: VeConvertParams) {
		return ve_convert(this.ctx.program, params);
	}

	async veVesting(params: VestingParams) {
		return vesting(this.ctx.program, params);
	}

	async getVoteConfig() {
		return get_vote_config(this.ctx.program);
	}

	async getVote(params: GetVoteParams) {
		return get_vote(this.ctx.program, params);
	}

	async getVoteEscrow(params: GetVoteEscrowParams) {
		return get_vote_escrow(this.ctx.program, params);
	}

	async getVotes(params: GetVoteParams) {
		return get_votes(this.ctx.program, params);
	}

	async createVote(params: CreateVoteParams) {
		return create_vote(this.ctx.program, params);
	}

	async createBribes(params: CreateBribesParams) {
		return create_bribes(this.ctx.program, params);
	}

	async claimReward(params: VoteRewardParams) {
		return vote_reward(this.ctx.program, params);
	}
}

export const buildClient = (ctx: VeContext): VeClient => {
	return new VeClient(ctx);
};
