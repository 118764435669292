import { AnchorProvider, Program, type Wallet } from "@coral-xyz/anchor";
import type { Connection, PublicKey } from "@solana/web3.js";
import { VE_PROGRAM_INTERFACE } from "./constants";
import type { Ve33 } from "./types/ve_33";

export class VeContext {
	readonly connection: Connection;
	readonly wallet: Wallet;
	readonly program: Program<Ve33>;
	readonly provider: AnchorProvider;

	constructor(
		connection: Connection,
		wallet: Wallet,
		program: Program<Ve33>,
		provider: AnchorProvider,
	) {
		this.connection = connection;
		this.wallet = wallet;
		this.program = program;
		this.provider = provider;
	}

	static create(
		connection: Connection,
		wallet: Wallet,
		programId: PublicKey,
	): VeContext {
		const anchorProvide = new AnchorProvider(connection, wallet, {
			commitment: "confirmed",
			preflightCommitment: "confirmed",
		});

		const program = new Program(VE_PROGRAM_INTERFACE, programId, anchorProvide);
		return new VeContext(connection, wallet, program, anchorProvide);
	}
}
