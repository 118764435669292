import { struct, u8 } from "@solana/buffer-layout";
import { bool, publicKey, u64 } from "@solana/buffer-layout-utils";
import type { PublicKey } from "@solana/web3.js";
import type BN from "bn.js";

export type GetLockParams = {
	owner: PublicKey;
	identifier: number;
};

export type CreateLockParams = {
	amount: BN;
	mintTokenProgramId: PublicKey;
	authority: PublicKey;
	refetch?: () => void;
};

export type IncreaseLockParams = {
	lockId: number;
	amount: BN;
	mintToken: PublicKey;
	authority: PublicKey;
	refetch?: () => void;
};

export type ExistLockParams = {
	lockId: number;
	mintToken: PublicKey;
	feeReceiver: PublicKey;
	authority: PublicKey;
	mintNftToken: PublicKey;
	refetch?: () => void;
};

export type TransferLockParams = {
	lockId: number;
	authority: PublicKey;
	receiver: PublicKey;
	mintToken: PublicKey;
	mintNftToken: PublicKey;
	refetch?: () => void;
};

export type MergeLockParams = {
	selectLockId?: number;
	targetLockId?: number;
	authority: PublicKey;
	mintToken?: PublicKey;
	mintNftToken?: PublicKey;
	refetch?: () => void;
};

export type SplitLockParams = {
	selectLockId?: number;
	amount: BN[];
	authority: PublicKey;
	mintToken: PublicKey;
	mintNftToken: PublicKey;
	refetch?: () => void;
};

export type LockInfo = {
	authority: PublicKey;
	lockTokenMint: PublicKey;
	lockNftMint: PublicKey;
	tokenAmount: BN;
	lockPower: BN;
	used: boolean;
	identifier: BN;
	rate: number;
	bump: number;
};

export const VE_LOCK_LAYOUT = struct([
	publicKey("tokenMintAddress"),
	u64("tokenAmount"),
	u64("lockPower"),
	bool("used"),

	u8("identifier"),
	publicKey("authority"),
	u8("bump"),
]);
