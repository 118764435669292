import type { Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { Ve33 } from "../types/ve_33";

export const lock = async (program: Program<Ve33>) => {
	const [lock] = PublicKey.findProgramAddressSync(
		[Buffer.from("lock")],
		program.programId,
	);
	const data = await program.account.lock.fetch(lock);
	return data;
};
