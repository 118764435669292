"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.twoHopSwapV2Ix = twoHopSwapV2Ix;
const public_1 = require("../../types/public");
const remaining_accounts_util_1 = require("../../utils/remaining-accounts-util");
/**
 * Perform a two-hop swap in this Yevefi
 *
 * #### Special Errors
 * - `ZeroTradableAmount` - User provided parameter `amount` is 0.
 * - `InvalidSqrtPriceLimitDirection` - User provided parameter `sqrt_price_limit` does not match the direction of the trade.
 * - `SqrtPriceOutOfBounds` - User provided parameter `sqrt_price_limit` is over Whirlppool's max/min bounds for sqrt-price.
 * - `InvalidTickArraySequence` - User provided tick-arrays are not in sequential order required to proceed in this trade direction.
 * - `TickArraySequenceInvalidIndex` - The swap loop attempted to access an invalid array index during the query of the next initialized tick.
 * - `TickArrayIndexOutofBounds` - The swap loop attempted to access an invalid array index during tick crossing.
 * - `LiquidityOverflow` - Liquidity value overflowed 128bits during tick crossing.
 * - `InvalidTickSpacing` - The swap pool was initialized with tick-spacing of 0.
 * - `InvalidIntermediaryMint` - Error if the intermediary mint between hop one and two do not equal.
 * - `DuplicateTwoHopPool` - Error if yevefi one & two are the same pool.
 * - `AmountCalcOverflow` - The required token amount exceeds the u64 range.
 * - `AmountRemainingOverflow` - Result does not match the specified amount.
 * - `DifferentYevefiTickArrayAccount` - The provided tick array account does not belong to the yevefi.
 * - `PartialFillError` - Partially filled when sqrtPriceLimit = 0 and amountSpecifiedIsInput = false.
 * - `IntermediateTokenAmountMismatch` - The amount of tokens received from the first hop does not match the amount sent to the second hop.
 *
 * ### Parameters
 * @category Instructions
 * @param context - Context object containing services required to generate the instruction
 * @param params - {@link TwoHopSwapV2Params} object
 * @returns - Instruction to perform the action.
 */
function twoHopSwapV2Ix(program, params) {
    const { amount, otherAmountThreshold, amountSpecifiedIsInput, aToBOne, aToBTwo, sqrtPriceLimitOne, sqrtPriceLimitTwo, yevefiOne, yevefiTwo, tokenMintInput, tokenMintIntermediate, tokenMintOutput, tokenProgramInput, tokenProgramIntermediate, tokenProgramOutput, tokenVaultOneInput, tokenVaultOneIntermediate, tokenVaultTwoIntermediate, tokenVaultTwoOutput, tokenAuthority, tokenTransferHookAccountsInput, tokenTransferHookAccountsIntermediate, tokenTransferHookAccountsOutput, tokenOwnerAccountInput, tokenOwnerAccountOutput, tickArrayOne0, tickArrayOne1, tickArrayOne2, tickArrayTwo0, tickArrayTwo1, tickArrayTwo2, oracleOne, oracleTwo, supplementalTickArraysOne, supplementalTickArraysTwo, } = params;
    const [remainingAccountsInfo, remainingAccounts] = new remaining_accounts_util_1.RemainingAccountsBuilder()
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookInput, tokenTransferHookAccountsInput)
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookIntermediate, tokenTransferHookAccountsIntermediate)
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.TransferHookOutput, tokenTransferHookAccountsOutput)
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.SupplementalTickArraysOne, (0, remaining_accounts_util_1.toSupplementalTickArrayAccountMetas)(supplementalTickArraysOne))
        .addSlice(remaining_accounts_util_1.RemainingAccountsType.SupplementalTickArraysTwo, (0, remaining_accounts_util_1.toSupplementalTickArrayAccountMetas)(supplementalTickArraysTwo))
        .build();
    const ix = program.instruction.twoHopSwapV2(amount, otherAmountThreshold, amountSpecifiedIsInput, aToBOne, aToBTwo, sqrtPriceLimitOne, sqrtPriceLimitTwo, remainingAccountsInfo, {
        accounts: {
            yevefiOne,
            yevefiTwo,
            tokenMintInput,
            tokenMintIntermediate,
            tokenMintOutput,
            tokenProgramInput,
            tokenProgramIntermediate,
            tokenProgramOutput,
            tokenOwnerAccountInput,
            tokenVaultOneInput,
            tokenVaultOneIntermediate,
            tokenVaultTwoIntermediate,
            tokenVaultTwoOutput,
            tokenOwnerAccountOutput,
            tokenAuthority,
            tickArrayOne0,
            tickArrayOne1,
            tickArrayOne2,
            tickArrayTwo0,
            tickArrayTwo1,
            tickArrayTwo2,
            oracleOne,
            oracleTwo,
            memoProgram: public_1.MEMO_PROGRAM_ADDRESS,
        },
        remainingAccounts,
    });
    return {
        instructions: [ix],
        cleanupInstructions: [],
        signers: [],
    };
}
