import type { TokenInfo } from "@solana/spl-token-registry";

export const YEVE: TokenInfo = {
	chainId: 103,
	address: "3jXKDhY5ex1cHfMoXG2mJoPbkm7SawQWkeUZC6bbdfM5",
	symbol: "YEVE",
	name: "YEVE",
	decimals: 9,
	logoURI: "/assets/tokens/yeve.png",
};

export const XYEVE: TokenInfo = {
	chainId: 103,
	address: "5B6kXoTZKzR1gJE7eXbuH9LjjuYZy7hMbEdbHzWv2qGv",
	symbol: "xYEVE",
	name: "xYEVE",
	decimals: 9,
	logoURI: "/assets/tokens/oyeve.png",
};

export const SOL: TokenInfo = {
	chainId: 103,
	address: "So11111111111111111111111111111111111111112",
	symbol: "SOL",
	name: "Wrapped SOL",
	decimals: 9,
	logoURI: "/assets/tokens/sol.png",
};

export const USDC: TokenInfo = {
	chainId: 103,
	address: "BRjpCHtyQLNCo8gqRUr8jtdAj5AjPYQaoqbvcZiHok1k",
	symbol: "USDC",
	name: "USDC",
	decimals: 6,
	logoURI: "/assets/tokens/usdc.png",
};
