import type { Program } from "@coral-xyz/anchor";
import type { PublicKey } from "@solana/web3.js";
import type { Ve33 } from "../types/ve_33";

export const get_locks = async (program: Program<Ve33>, owner: PublicKey) => {
	const filters = [
		{
			memcmp: {
				offset: 40,
				bytes: owner.toBase58(),
			},
		},
	];
	return await program.account.lockData.all(filters);
};
