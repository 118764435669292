import { BN, type Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import type { GetLockParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const get_lock = async (
	program: Program<Ve33>,
	params: GetLockParams,
) => {
	const { identifier, owner } = params;
	const [lockData] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			owner.toBuffer(),
			new BN(identifier).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);
	const data = await program.account.lockData.fetch(lockData);
	return data;
};
