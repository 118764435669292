import { BN, type Program } from "@coral-xyz/anchor";
import bs58 from "bs58";
import type { GetVoteParams, VoteData } from ".";
import type { Ve33 } from "../types/ve_33";

export const get_votes = async (
	program: Program<Ve33>,
	params: GetVoteParams,
) => {
	const filters = [
		{
			memcmp: {
				offset: 8 + 32,
				bytes: bs58.encode(new BN(params.epoch).toArrayLike(Buffer, "le", 8)),
			},
		},
	];
	const data = await program.account.voteData.all(filters);
	return data.map((item) => item.account) as unknown as VoteData[];
};
