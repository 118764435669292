import { BN, type Program } from "@coral-xyz/anchor";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	NATIVE_MINT,
	TOKEN_PROGRAM_ID,
	createSyncNativeInstruction,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import type { CreateBribesParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const create_bribes = async (
	program: Program<Ve33>,
	params: CreateBribesParams,
) => {
	const { pool, mintToken, amount, authority, epoch } = params;
	const mintTokenAccount = getAssociatedTokenAddressSync(mintToken, authority);
	const [voteDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote_data"),
			pool.toBuffer(),
			new BN(epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);
	const bribesVault = getAssociatedTokenAddressSync(
		mintToken,
		voteDataPDA,
		true,
	);

	const transaction = new Transaction();
	if (mintToken.equals(NATIVE_MINT)) {
		transaction.add(
			SystemProgram.transfer({
				fromPubkey: authority,
				toPubkey: mintTokenAccount,
				lamports: amount.toNumber(),
			}),
			createSyncNativeInstruction(mintTokenAccount),
		);
	}

	const ix = await program.methods
		.createVoteBribes(amount)
		.accounts({
			funder: authority,
			bribesMint: mintToken,
			bribesTokenAccount: mintTokenAccount,
			bribesVault: bribesVault,
			voteData: voteDataPDA,
			systemProgram: SystemProgram.programId,
			associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
			tokenProgram: TOKEN_PROGRAM_ID,
		})
		.transaction();

	transaction.add(ix);

	return transaction;
};
