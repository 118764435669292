import { BN, type Program } from "@coral-xyz/anchor";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
} from "@solana/spl-token";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { TransferLockParams } from ".";
import type { Ve33 } from "../types/ve_33";

export const transfer_lock = async (
	program: Program<Ve33>,
	params: TransferLockParams,
) => {
	const { lockId, receiver, mintToken, mintNftToken, authority } = params;

	if (!mintNftToken || !lockId || !authority || !receiver) {
		throw new Error("Missing params");
	}

	if (!PublicKey.isOnCurve(receiver.toBuffer())) {
		throw new Error("Invalid receiver address");
	}

	const [lockPDA] = PublicKey.findProgramAddressSync(
		[Buffer.from("lock")],
		program.programId,
	);

	const lock = await program.account.lock.fetch(lockPDA);
	if (!lock) {
		throw new Error("Lock not found");
	}

	const [lockDataPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(lockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const sourcelockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		lockDataPDA,
		true,
	);

	const [lockData] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			receiver.toBuffer(),
			new BN(lock.nextLockIdentifier).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const lockVaultAccount = getAssociatedTokenAddressSync(
		mintToken,
		lockData,
		true,
	);

	const associatedTokenAccount = getAssociatedTokenAddressSync(
		mintNftToken,
		authority,
	);

	const receiverAssociatedTokenAccount = getAssociatedTokenAddressSync(
		mintNftToken,
		receiver,
	);

	const ix = await program.methods.transferLock().accounts({
		funder: authority,
		lock: lockPDA,
		sourceData: lockDataPDA,
		sourceLockVaultAccount: sourcelockVaultAccount,
		receiver: receiver,
		lockTokenMint: mintToken,
		lockData: lockData,
		lockVaultAccount: lockVaultAccount,
		mint: mintNftToken,
		associatedTokenAccount: associatedTokenAccount,
		receiverTokenAccount: receiverAssociatedTokenAccount,

		systemProgram: SystemProgram.programId,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
		tokenProgram: TOKEN_PROGRAM_ID,
	});

	return ix;
};
