import { BN, type Program } from "@coral-xyz/anchor";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import type { CreateVoteParams } from ".";
import { get_lock } from "../lock/get_lock";
import type { Ve33 } from "../types/ve_33";
import { get_vote_config } from "./get_vote_config";

export const create_vote = async (
	program: Program<Ve33>,
	params: CreateVoteParams,
) => {
	const { pools, powers, lockId, authority } = params;

	const voteConfig = await get_vote_config(program);
	if (!voteConfig) {
		throw new Error("Vote config not initialized");
	}

	const [votePDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote"),
			new BN(voteConfig.epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);
	if (!votePDA) {
		throw new Error("Vote not initialized");
	}

	const lockData = await get_lock(program, {
		identifier: lockId,
		owner: authority,
	});

	if (!lockData) {
		throw new Error("Lock not initialized");
	}

	const [lockPDA] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("lock_data"),
			authority.toBuffer(),
			new BN(lockId).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const [voteEscrow] = PublicKey.findProgramAddressSync(
		[
			Buffer.from("vote_escrow"),
			authority.toBuffer(),
			new BN(lockData.identifier).toArrayLike(Buffer, "le", 8),
			new BN(voteConfig.epoch).toArrayLike(Buffer, "le", 8),
		],
		program.programId,
	);

	const amount = powers.map((item) =>
		lockData.lockPower.mul(new BN(item)).div(new BN(100)),
	);

	const tx = await program.methods
		.createVoteEscrow(pools, amount)
		.accounts({
			funder: authority,
			vote: votePDA,
			lockData: lockPDA,
			voteEscrow: voteEscrow,
			systemProgram: SystemProgram.programId,
		})
		.transaction();

	return tx;
};
